import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Tab, Table, Modal, Nav, Row } from "react-bootstrap";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import apiCall from "services/api";
import regexPatterns from "common/regexPatterns";
import Constants from "common/Constants";
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";

interface AddExistingUserModalProps {
  show: boolean;
  handleClose: () => void;
  setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
  alias: any;
  allUsers: any;
  toast: any;
  entity_id_proj: any;
  navigation: any;
}
interface User {
  id: number;
  first_name: string;
  last_name: string;
}
type ReactSelectOption = {
  value: number;
  label: string;
};

const AddExistingUserModal = ({ show, handleClose, setAddStatus, alias, allUsers, toast, navigation, entity_id_proj }: AddExistingUserModalProps) => {
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { designation } = useMaster();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState<boolean>(false);
  const [projAlias,] = useState(alias)

  // for new user
  const [formDataNewUser, setFormDataNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_password: "",
    user_password_re: "",
    short_name: "",
    designation_id: "",
    role_in_project: "",
    is_meeting_admin: false,
    is_diary_admin: false,
    is_project_admin: false,
    is_story_admin: false,
  });

  const [errorNewUser, setErrorNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_password: "",
    user_password_re: "",
    short_name: "",
    designation_id: "",
    role_in_project: "",
    is_meeting_admin: "",
    is_diary_admin: "",
    is_project_admin: "",
    is_story_admin: "",
  });
  const [designationListData, setDesignationListData] = useState<ReactSelectOption[] | undefined>();

  // for existing user
  const [formData, setFormData] = useState({
    user_id: users.length > 0 ? users[0].id : "",
    is_project_admin: false,
    is_story_admin: false,
    user_password: false,
    is_meeting_admin: false,
    is_diary_admin: false,
    role_in_project: "",
    alias: "",
  });

  //for existing user errors
  const [errorExisting, setErrorExisting] = useState({
    user_id: "",
    is_project_admin: "",
    is_story_admin: "",
    user_password: "",
    is_meeting_admin: "",
    role_in_project: "",
    is_diary_admin: "",
    alias: "",
  });

  //for existing user
  useEffect(() => {
    loadUsers();
    setFormData((prevData) => ({
      ...prevData,
      alias: alias
    }))


  }, [, alias]);

  //for existing user
  const loadUsers = async () => {
    try {

      const response = await apiCall({
        // url: `project/${projAlias}/users`,
        url: `admin/user/list?pagination=0&status=${10}`,   //using this admin api, because if loggedin user is admin
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
  
      let userData = response?.data?.users?.data
      let remainingUsers = userData.filter((user: User) => !allUsers.includes(user.id))
      setUsers(remainingUsers);

    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  //for existing user
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "user_id") {
      if (!value.trim()) {
        errorMessage = "User is required.";
      }
    }

    if (name === "role_in_project") {
      if (!value.trim()) {
        errorMessage = "Role is required.";
      } if (!regexPatterns.titleRegex14.test(value)) {
        errorMessage = "Role should be 3 to 14 alphabetic characters.";
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorExisting({
      ...errorExisting,
      [name]: errorMessage,
    });
  };
  //for existing user
  const handleSubmitExisting = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();

    // Add the following line to set the form validation state
    setValidated(true);

    if (           // Check if there are any errors before making the API call
      errorExisting.user_id ||
      errorExisting.role_in_project

    ) {
      setLoading(false);
      return;
    }
    if (!formData.role_in_project.trim() || !formData.user_id) {
      setErrorExisting({
        ...errorExisting,
        role_in_project: !formData.role_in_project.trim() ? "Role is required." : "",
        user_id: !formData.user_id ? "User is required." : "ewetrt",

      });
      setLoading(false);
      return;
    }

    // Perform any necessary logic or API calls here
    const formDataSubmit = new FormData();
    formDataSubmit.append("user_id", String(formData?.user_id));
    formDataSubmit.append("is_project_admin", String(formData.is_project_admin ? 1 : 0));
    formDataSubmit.append("is_story_admin", String(formData.is_story_admin ? 1 : 0));
    formDataSubmit.append("is_meeting_admin", String(formData.is_meeting_admin ? 1 : 0));
    formDataSubmit.append("is_diary_admin", String(formData.is_diary_admin ? 1 : 0));
    formDataSubmit.append("role_in_project", String(formData.role_in_project));
    formDataSubmit.append("user_designation", "1");

    try {
      const response = await apiCall({
        url: `admin/project/${projAlias}/permissions-create`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;
          // Dynamically set errors based on the response 
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setErrorExisting((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          toast.success(response.data.message, {
            position: "top-center"
          });
          handleClose();
          setAddStatus(true);
        }
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed 
    }
  };

  useEffect(() => {
    const transformedDataDesignation = designation?.map((item) => ({
      value: item.id,
      label: `${item.title}`,
    }));
    setDesignationListData(transformedDataDesignation);

  }, [designation]);

  /*for new user*/


  const handleInputChangeNewUser = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Validation for first name
    if (name === "first_name") {
     if (!regexPatterns.titleRegexNoSpace.test(value)) {
        errorMessage = "First name should contain at least 3 alphabetic characters without space.";
      }
    }

    // Validation for last name
    if (name === "last_name") {
      if (value.trim() && !regexPatterns.titleRegex.test(value)) {
        errorMessage = "Last name should contain at least 3 alphabetic characters.";
      }
    }

    if (name === "short_name") {
      if (value.trim().length !== 2) {
        errorMessage = "Short name should be 2 characters.";
      }
    }
    // Validation for email
    if (name === "email") {
      if (!value.trim()) {
        errorMessage = "Email is required.";
      } else if (!regexPatterns.emailRegex.test(value)) {
        errorMessage = "Invalid email address.";
      }
    }

    // Validation for password
    if (name === "user_password") {
      if (!regexPatterns.passwordRedex3.test(value)) {
        errorMessage = "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character (!, @, #, $, %, ^, &, *, (, ), [, ], -, _), and be between 6 and 14 characters long.";
      } else if (value !== formDataNewUser.user_password_re && formDataNewUser.user_password_re !== '') {
        errorMessage = "Passwords and Repeat Password do not match.";
      }else if(value === formDataNewUser.user_password_re){
       
        setErrorNewUser(prevErrors => ({
          ...prevErrors,
          user_password_re: "",
        }));
      
      }
    }

    // Validation for matching passwords
    if (name === "user_password_re") {
      if (value !== formDataNewUser.user_password) {
        errorMessage = "Passwords and Repeat Password do not match.";
      }
    }


    if (name === "role_in_project") {
      if (!regexPatterns.titleRegex14.test(value)) {
        errorMessage = "Role should be 3 to 14 alphabetic characters.";
      }
    }

    // Handle description input
    if (name === "description" && value.trim() !== "") {
      if (value.trim().length > 255) {
        errorMessage = "Description should not exceed 255 characters.";
      }
    }


    // Handle description input
    if (name === "designation_id") {
      if (!value.trim()) {
        errorMessage = "Designation is required.";
      }
    }


    setFormDataNewUser(prevData => ({
      ...prevData,
      [name]: value,
    }));


    setErrorNewUser(prevErrors => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  /*for new user*/
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();

    // Add the following line to set the form validation state
    setValidated(true);



    if (           // Check if there are any errors before making the API call
      errorNewUser.first_name ||
      errorNewUser.last_name ||
      errorNewUser.email ||
      errorNewUser.user_password ||
      errorNewUser.user_password_re ||
      errorNewUser.short_name ||
      errorNewUser.designation_id ||
      errorNewUser.role_in_project ||
      errorNewUser.is_meeting_admin ||
      errorNewUser.is_diary_admin ||
      errorNewUser.is_project_admin ||
      errorNewUser.is_story_admin

    ) {
      return;
    }

    if (
      // !formDataNewUser.first_name.trim() ||
      // !formDataNewUser.short_name.trim() ||
      !formDataNewUser.email.trim() 
      // !formDataNewUser.user_password.trim() ||
      // !formDataNewUser.user_password_re.trim() ||
      // !formDataNewUser.designation_id ||
      // !formDataNewUser.role_in_project
    ) {
      setErrorNewUser({
        ...errorNewUser,
        // first_name: !formDataNewUser.first_name.trim() ? "First name is required." : "",
        // short_name: !formDataNewUser.short_name.trim() ? "Short name is required." : "",
        email: !formDataNewUser.email.trim() ? "Email is required." : "",
        // user_password: !formDataNewUser.user_password.trim() ? "Password is required." : "",
        // user_password_re: !formDataNewUser.user_password_re.trim() ? "Re-password is required." : "",
        // designation_id: !formDataNewUser.designation_id ? "Designation is required." : "",
        // role_in_project: !formDataNewUser.role_in_project.trim() ? "Role is required." : "",
      });
      return;
    }

    const formDataSubmit = new FormData();
    formDataSubmit.append('first_name', formDataNewUser?.first_name);
    formDataSubmit.append('last_name', formDataNewUser?.last_name);
    formDataSubmit.append('short_name', formDataNewUser?.short_name);
    formDataSubmit.append('email', formDataNewUser?.email);
    formDataSubmit.append('user_password', formDataNewUser?.user_password);
    formDataSubmit.append('designation_id', formDataNewUser?.designation_id);
    formDataSubmit.append('role_in_project', formDataNewUser?.role_in_project);
    formDataSubmit.append('is_meeting_admin', String(formDataNewUser.is_meeting_admin == true ? 1 : 0));
    formDataSubmit.append('is_diary_admin', String(formDataNewUser.is_diary_admin == true ? 1 : 0));
    formDataSubmit.append('is_project_admin', String(formDataNewUser.is_project_admin == true ? 1 : 0));
    formDataSubmit.append('is_story_admin', String(formDataNewUser.is_story_admin == true ? 1 : 0));

    try {
      const response = await apiCall({
        url: `admin/project/${projAlias}/user-create-and-project-assign`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;

          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setErrorNewUser((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          toast.success(response.data.message, {
            position: "top-center"
          });
          handleClose();
          setAddStatus(true);
        }
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    }
  }
  return (
    <Modal show={show} onHide={handleClose} className="p-0" centered size="lg">
      <Modal.Header className="border-0 p-4">
        <h5 className="modal-title text-1000 fs-7 lh-sm">Add user</h5>
        <Button className="p-1" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} className="fs-9" />
        </Button>
      </Modal.Header>
      <Row>

        <Tab.Container defaultActiveKey="existinguser">
          <Nav variant="underline" className="justify-content-center gap-3">
            <Nav.Item className="under_line">
              <Nav.Link eventKey="existinguser">Existing User</Nav.Link>
            </Nav.Item>
            <Nav.Item className="under_line">
              <Nav.Link eventKey="pastuser">Add New User</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content style={{ marginTop: "-2px" }}>
            <Tab.Pane eventKey="existinguser">
              <PhoenixDocCard className='pt-3  pb-4 mt-1 border_design'>

                <Form noValidate validated={validated} onSubmit={handleSubmitExisting}>
                  <Modal.Body className="pt-4 pb-2 ">
                    <Row className="mb-3 d-flex gap-1">
                      <Col>
                        <Form.Group className=" text-start">
                          <FloatingLabel
                            controlId="floatingSelectTask"
                            label="User *"
                          >
                            <Form.Select required value={formData.user_id} name='user_id'
                              onChange={handleInputChange}
                              isInvalid={!!errorExisting.user_id}
                            >
                              <option value={""}>Select</option>
                              {users && users?.map((user, index: number) => {
                                return (
                                  <option value={user.id} key={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                                )
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errorExisting?.user_id ? errorExisting?.user_id : ""}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3 d-flex gap-1">
                      <Col>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label='ROLL WITH IN PROJECT *'
                        >
                          <Form.Control
                            type="text"
                            placeholder="Role"
                            name="role_in_project"
                            value={formData?.role_in_project}
                            onChange={handleInputChange}
                            isInvalid={!!errorExisting?.role_in_project}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorExisting?.role_in_project}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between flex-wrap">
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_project_admin"
                          id="is_project_admin"
                          value={formData.is_project_admin.toString()}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              is_project_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="projectadmin" className="mb-0">
                          Project Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_story_admin"
                          id="is_story_admin"
                          value={formData.is_story_admin.toString()}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              is_story_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="storyadmin" className="mb-0">
                          Story Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_meeting_admin"
                          id="is_meeting_admin"
                          value={formData.is_meeting_admin.toString()}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              is_meeting_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="meetingadmin" className="mb-0">
                          Meeting Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_diary_admin"
                          id="is_diary_admin"
                          value={formData.is_diary_admin.toString()}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              is_diary_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="diaryadmin" className="mb-0">
                          Diary Admin
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                      variant="phoenix-primary"
                      size="sm"
                      className="px-9 fs-9  my-0 btn btn-outline-primary bg-transparent"
                      type="submit"
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>

              </PhoenixDocCard>
            </Tab.Pane>
            <Tab.Pane eventKey="pastuser">
              <PhoenixDocCard className='pt-3  pb-4 mt-1 border_design' >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Modal.Body className="pt-4 pb-2 ">
                    <Row className="">
                      <Col md={6} className="mb-3">
                        <Form.Group className=" text-start">
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label={`${Constants.first_name}`}
                          >
                            <Form.Control
                             
                              type="text"
                              placeholder="First Name"
                              name="first_name"
                              value={formDataNewUser.first_name}
                              onChange={handleInputChangeNewUser}
                              isInvalid={!!errorNewUser.first_name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorNewUser?.first_name}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label={`${Constants.last_name}`}
                        >
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={formDataNewUser.last_name}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser.last_name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.last_name}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label={`${Constants.short_name}`}
                        >
                          <Form.Control
                            
                            type="text"
                            placeholder="Short Name"
                            name="short_name"
                            value={formDataNewUser.short_name}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser.short_name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.short_name}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label={`${Constants.email} *`}
                        >
                          <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formDataNewUser.email}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.email}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label='User Password '
                        >
                          <Form.Control
                         
                            type="text"
                            placeholder="Password"
                            name="user_password"
                            value={formDataNewUser.user_password}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser.user_password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.user_password}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label='Repeat Password '
                        >
                          <Form.Control
                         
                            type="text"
                            placeholder="Re-Password"
                            name="user_password_re"
                            value={formDataNewUser.user_password_re}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser.user_password_re}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.user_password_re}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group className=" text-start">
                          <FloatingLabel controlId="lead-woner" label="Designation">
                            <Form.Select
                            
                              value={formDataNewUser.designation_id}
                              name="designation_id"
                              className="w-100"
                              onChange={handleInputChangeNewUser}
                              isInvalid={!!errorNewUser.designation_id}
                            >
                              <option value="" key={0}>Select</option>
                              {
                                designationListData?.map((item, index) => (
                                  <option value={item.value} key={item.value}>{item.label}</option>
                                ))
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errorNewUser?.designation_id}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>

                      </Col>
                      <Col md={6} className="mb-3">
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label='ROLL WITH IN PROJECT '
                        >
                          <Form.Control
                            type="text"
                            placeholder="Role"
                            name="role_in_project"
                            value={formDataNewUser?.role_in_project}
                            onChange={handleInputChangeNewUser}
                            isInvalid={!!errorNewUser?.role_in_project}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorNewUser?.role_in_project}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    {/* <div className="d-flex justify-content-between flex-wrap">
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_project_admin"
                          id="is_project_admin"
                          checked={Number(formDataNewUser.is_project_admin) === 1 ? true : false}
                          onChange={(e) => {
                            setFormDataNewUser((prevData) => ({
                              ...prevData,
                              is_project_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="projectadmin" className="mb-0">
                          Project Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_story_admin"
                          id="is_story_admin"
                          checked={Number(formDataNewUser.is_story_admin) === 1 ? true : false}
                          onChange={(e) => {
                            setFormDataNewUser((prevData) => ({
                              ...prevData,
                              is_story_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="storyadmin" className="mb-0">
                          Story Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_meeting_admin"
                          id="is_meeting_admin"
                          checked={Number(formDataNewUser.is_meeting_admin) === 1 ? true : false}
                          onChange={(e) => {
                            setFormDataNewUser((prevData) => ({
                              ...prevData,
                              is_meeting_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="meetingadmin" className="mb-0">
                          Meeting Admin
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check type="checkbox" className="mb-0">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_diary_admin"
                          id="is_diary_admin"
                          checked={Number(formDataNewUser.is_diary_admin) === 1 ? true : false}
                          onChange={(e) => {
                            setFormDataNewUser((prevData) => ({
                              ...prevData,
                              is_diary_admin: e.target.checked
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="diaryadmin" className="mb-0">
                          Diary Admin
                        </Form.Check.Label>
                      </Form.Check>
                    </div> */}
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                      variant="phoenix-primary"
                      size="sm"
                      className="px-9 fs-9  my-0 btn btn-outline-primary bg-transparent"
                      type="submit"
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>
              </PhoenixDocCard>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>


      </Row>
    </Modal>
  );
};

export default AddExistingUserModal;
