import axios from 'axios';
import Constants from 'common/Constants';
import { getImageDimensions } from 'common/getImageDimensions';
import regexPatterns from 'common/regexPatterns';
import Button from 'components/base/Button';
import ReactSelect from 'components/base/ReactSelect';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { useAuth } from 'providers/AuthContext';
import { useMaster } from 'providers/MasterContext';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate, NavigateFunction, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { timeStampToDbDate } from 'common/timeStampToDate';
import DatePicker2 from 'components/base/DatePicker2';
import PageBreadcrumb, { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { getPageBreadCrumbs } from 'services/getPageBreadCrumbs';

interface FormData {
  title: string;
  projectlogo: File | null;
  start_date: Date | string | null;
  end_date: Date | string | null;
  entity_id: string;
  category: string;
  phase: string;
  donar: string;
  description: string;
  tags: string[];
}

interface formError {
  title: string;
  projectlogo: string;
  start_date: string;
  end_date: string;
  entity_id: string;
  category: string;
  phase: string;
  donar: string;
  description: string;
  tags: string[];
}

type ReactSelectOption = {
  value: number;
  label: string;
};

const CreateProject: React.FC = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("CreateProject")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpace, workSpaceTkn } = useAuth();
  const { tags, projCategory,  phases } = useMaster();
  const [tagsData, setTagsData] = useState<ReactSelectOption[] | undefined>();
  const [validated, setValidated] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type 

  useSettingsMountEffect({
    showSettingPanelButton: false
  });

  const [formData, setFormData] = useState<FormData>({
    title: '',
    projectlogo: null, // Initialize with null
    start_date: null,
    end_date: null,
    entity_id: '',
    category: '',
    phase: '',
    donar: '',
    description: '',
    tags: [],
  });
  const [error, setError] = useState<formError>({
    title: '',
    projectlogo: '',
    start_date: '',
    entity_id: '',
    end_date: '',
    category: '',
    phase: '',
    donar: '',
    description: '',
    tags: [],
  });

  const [submitError, setSubmitError] = useState<any>("")
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
    // Handle ChangeEvent case
    const { name, value } = 'target' in e ? e.target : e;
    let errorMessage = "";

    if (name === 'projectlogo') {
      const fileInput = e.target as HTMLInputElement;
      const selectedFile = fileInput.files && fileInput.files[0]; // Check if files are present

      if (selectedFile) {
        const fileSizeKB = selectedFile.size / 1024; // Convert to KB
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        if (fileSizeKB > 15) {
          errorMessage = "File size exceeds the maximum limit of 15KB.";
        } else if (!allowedTypes.includes(selectedFile.type) && !allowedTypes.includes(`image/${fileExtension}`)) {
          errorMessage = "Only JPEG, JPG, or PNG files are allowed.";
        } else {
          try {
            const { width, height } = await getImageDimensions(selectedFile);

            if (width < 32 || height < 32 || width > 32 || height > 32) {
              // errorMessage = "Image dimensions should be between 25x25 and 50x50 pixels.";
              errorMessage = "Image dimensions should be exactly 32x32 pixels.";
            } else {
              setFormData({
                ...formData,
                [name]: selectedFile
              });
            }
          } catch (error) {
            errorMessage = "Error getting image dimensions.";
          }
        }
      } else {
        setFormData({
          ...formData,
          [name]: null
        });
      }

      setError({
        ...error,
        [name]: errorMessage,
      });
      return; // Exit early
    }
    // Validation for email
    if (name === "title") {
      if (!value.trim()) {
        errorMessage = "Title is required.";
      } else if (!regexPatterns.titleRegex.test(value)) {
        errorMessage = "Title should contain at least 3 and max 75  alphabetic characters.";
      }
    }

    // Validation for start date
    if (name === "start_date") {
      if (!value.trim()) {
        errorMessage = "Start date is required.";
      } else if (formData.end_date && new Date(value) > new Date(formData.end_date)) {
        errorMessage = "Start date should be less than or equal to end date.";
      }
    }
    // Validation for end date
    if (name === "end_date") {
      if (!value.trim()) {
        errorMessage = "End date is required.";
      } else if (formData.start_date && value !== null && new Date(value) < new Date(formData.start_date)) {
        errorMessage = "End date should be greater than or equal to start date.";
      }
    }
    // Handle description input
    if (name === "description" && value.trim() !== "") {
      if (value.trim().length > 255) {
        errorMessage = "Description should not exceed 255 characters.";
      }
    }

    // Handle description input
    if (name === "donar" && value.trim() !== "") {
      if (!regexPatterns.titleAlphaNumeric.test(value)) {
        errorMessage = "Donar should be 3 to 75 alphanumeric (a-z, A-Z, 0-9) characters.";
      }
    }

    if (name === "end_date" || name === "start_date") {
      const date = new Date(value);
      let newValue = timeStampToDbDate(date)
      setFormData({
        ...formData,
        [name]: newValue,
      });

    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

    }
    setError({
      ...error,
      [name]: errorMessage,
    });

  };


  const handleTagsChange = (selectedOptions: any) => {
    setFormData({
      ...formData,
      tags: selectedOptions.map((option: any) => option.value),
    });
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);
    if (           // Check if there are any errors before making the API call
      error.title ||
      error.start_date ||
      error.end_date ||
      error.category ||
      error.phase

    ) {
      return;
    }


    if (
      !formData.title.trim() ||
      !formData.category ||
      !formData.phase ||
      !formData.start_date ||
      !formData.end_date ||
      new Date(formData.end_date) < new Date(formData.start_date)
    ) {
      setError({
        ...error,
        title: !formData.title.trim() ? "Title is required." : "",
        category: !formData.category ? "Category is required." : "",
        phase: !formData.phase ? "Phase is required." : "",
        start_date: !formData.start_date ? "Start date is required." : "",
        end_date: !formData.end_date ? "End date is required." : formData.start_date && formData.end_date && new Date(formData.end_date) < new Date(formData.start_date) ? "End date should be greater than or equal to start date." : ""

      });
      return;
    }

    const formDataForSubmission = new FormData();
    formDataForSubmission.append('title', formData?.title);
    formDataForSubmission.append('description', formData.description);
    formDataForSubmission.append('category', formData.category);
    formDataForSubmission.append('phase', formData.phase);
    formDataForSubmission.append('start_date', String(formData.start_date));
    formDataForSubmission.append('end_date', String(formData.end_date));
    formDataForSubmission.append('donar', formData.donar);
    formDataForSubmission.append('entity_id', formData.entity_id);
    // formDataForSubmission.append('tags', formData.tags.join(','));
    formData?.tags?.forEach((tag, index) => {
      formDataForSubmission.append(`tags[${index}]`, tag);
    });
    if (formData.projectlogo) {
      formDataForSubmission.append('logo', formData.projectlogo);
    }
    try {
      const response = await axios.post(`${Constants.BASE_URL2}project/create`, formDataForSubmission, {
        headers: {
          'x-api-key': 'web_qwertyuiop', // Add custom headers here
          'x-access-token': userTkn,
          'workspace': workSpaceTkn,
          "Access-Control-Allow-Origin": "*"
        },
      });
      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;

          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          toast.success(response.data.message, {
            position: "top-center"
          });
          navigation(`/${workSpace?.alias}/project/list`)
        }



      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        toast.error(error?.message, {
          position: "top-center"
        });
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    }
  };

  useEffect(() => {
    const transformedData = tags?.map(item => ({
      value: item.id,
      label: item.name
    }));
    setTagsData(transformedData)
  }, [tags])

  //update Breadcrumb
  useEffect(() => {
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <h2 className="mb-4">{Constants.create_project}</h2>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Row>
          <Col xs={12} xl={9}>
            <Row className="g-3 mb-6">
              <Col sm={6} md={12}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={`${Constants.project_title} *`}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Project title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      isInvalid={!!error.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.title ? error?.title : " Please enter the project title."}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <DatePicker2
                    value={formData.start_date ? new Date(formData.start_date).toLocaleDateString('en-GB') : ''}
                    onChange={(date: Date[]) => {
                      handleInputChange({
                        target: {
                          name: 'start_date',
                          value: date[0].toString()
                        }
                      });
                    }}
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    isError={error?.start_date}
                    id="start_date"
                    name="start_date"
                    render={(_, ref) => {
                      const isInvalid = !!error.start_date;
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            ref={ref}
                            placeholder="d-m-y"
                            readOnly
                            isInvalid={isInvalid}
                          />
                          <label htmlFor="startDate" className="ps-6">
                            {`${Constants.start_date} *`}
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {error?.start_date}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      );
                    }}
                  />

                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <DatePicker2
                    value={formData.end_date ? new Date(formData.end_date).toLocaleDateString('en-IN') : ''}
                    onChange={(date: Date[]) => {
                      handleInputChange({
                        target: {
                          name: 'end_date',
                          value: date[0].toString()
                        }
                      });
                    }}
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    isError={error?.start_date}
                    id="end_date"
                    name="end_date"
                    render={(_, ref) => {
                      const isInvalid = !!error.end_date;
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            ref={ref}
                            placeholder="d-m-y"
                            readOnly
                            isInvalid={isInvalid}
                          />
                          <label htmlFor="startDate" className="ps-6">
                            {`${Constants.end_date} *`}
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {error?.end_date}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      );
                    }}
                  />

                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingSelectTask"
                    label={`${Constants.category} *`}
                  >
                    <Form.Select required value={formData.category} name='category'
                      onChange={handleInputChange}
                      isInvalid={!!error.category}
                    >
                      <option value={""}>Select</option>
                      {projCategory && projCategory?.map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>{item?.title}</option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {error?.category}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingSelectPrivacy"
                    label={`${Constants.phase} *`}
                  >
                    <Form.Select required value={formData.phase} name='phase'
                      onChange={handleInputChange}
                      isInvalid={!!error.phase}
                    >
                      <option value={""}>Select phase</option>
                      {phases && phases?.map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>{item?.title}</option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {error?.phase ? error?.phase : "Please select the phase"}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className="text-start">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={`Enter Donner/Fund Organization`}
                  >
                    <Form.Control
                      type="text" placeholder="Enter Donner/Fund Organization"
                      name="donar"
                      value={formData.donar}
                      onChange={handleInputChange}
                      isInvalid={!!error.donar}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.donar}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingSelectPrivacy"
                    label="Entity"
                  >
                    <Form.Select value={formData.entity_id} name='entity_id' onChange={handleInputChange}>
                      <option value={""}>Select</option>
                      {/* {entity && entity?.map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>{item?.name}</option>
                        )
                      })} */}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select the Entity
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className='input-group custom-file-button'>
                  <label className="input-group-text" htmlFor="inputGroupFile">Upload Icon</label>
                  <Form.Control type="file" size='lg' name='projectlogo' onChange={handleInputChange} isInvalid={!!error.projectlogo} />
                  <Form.Control.Feedback type="invalid">
                    {error?.projectlogo ? error?.projectlogo : " Please enter the start date."}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} className="">
                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label={`Discription`}
                >
                  <Form.Control
                    type="text"
                    placeholder="Leave a comment here"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!error.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.description}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12} className="">
                <ReactSelect
                  isMulti
                  placeholder="Add tags"
                  options={tagsData}
                  classNames={{
                    control: () => 'py-3',
                    valueContainer: () => 'lh-1'
                  }}
                  onChange={handleTagsChange}
                />
              </Col>
              <Col xs={12} className="gy-6">
                {submitError && <div className="invalid-feedback" style={{ display: "block" }}>{submitError}</div>}
                <div className="d-flex justify-content-end gap-3">
                  <Button variant="phoenix-primary" className="px-5" onClick={() => { navigation(`/${workSpace?.alias}/project/list`); }}>
                    Cancel
                  </Button>
                  <Button variant="outline-primary" type="submit" className="px-5 px-sm-15">
                    Create Project
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default CreateProject;
