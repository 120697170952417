// MasterContext.tsx
import axios, { AxiosError } from 'axios';
import Constants from 'common/Constants';
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';


// Define the context type
type MasterContextType = {
    tags: TagsItem[] | null;
    projCategory: ProjectCategoryItem[] | null;
    designation: DesignationItem[] | null;
    phases: PhasesItem[] | null;
    priority: PriorityItem[] | null;
    refetchData: () => void;
};

//Define the EntityItem interface

interface EntityItem {
    id: number;
    name: string;
    short_name: string;
    alias: string;
    status: number;
}

//Define the TagsItem interface

interface TagsItem {
    id: number;
    name: string;
    status: number;
}

//Define the ProjectCategoryItem interface

interface ProjectCategoryItem {
    id: number;
    title: string;
    orderby: number;
    status: number;
}

//Define the ProjectCategoryItem interface

interface DesignationItem {
    id: number;
    title: string;
    status: number;
}
interface PhasesItem {
    id: number;
    title: string;
}

//Define the Priority interface

interface PriorityItem {
    id: number;
    title: string;
    color: string;
    orderby: number;
    status: number;
}

type WorkSpace = {
    id: number;
    uuid: string;
    title: string;
    alias: string;
    status: number;
    is_super_admin: number;
    is_default: boolean;
}
//define the context type (tags)

// Create the context
const MasterContext = createContext<MasterContextType | undefined>(undefined);

// Define a helper function to use the context
export function useMaster() {
    const context = useContext(MasterContext);
    if (!context) {
        throw new Error('useMaster must be used within a MasterProvider');
    }
    return context;
}

// Define the MasterProvider component
type MasterProviderProps = {
    children: ReactNode;
};

export function MasterProvider({ children }: MasterProviderProps) {
    const { userTkn, isAuthenticated, workSpaceTkn, setWorkspaceList, workSpace, signOut, setIsUserAdmin } = useAuth();
    const [tags, setTags] = useState<TagsItem[] | null>(null);
    const [projCategory, setProjCategory] = useState<ProjectCategoryItem[] | null>(null);
    const [designation, setDesignation] = useState<DesignationItem[] | null>(null);
    const [phases, setPhases] = useState<PhasesItem[] | null>(null);
    const [priority, setPriority] = useState<PriorityItem[] | null>(null);


    useEffect(() => {
        
        const rawAccessToken = localStorage.getItem('accessToken') || '{}';


        let accessToken: any = JSON.parse(rawAccessToken);


        const fetchUserFromLocalStorage = async () => {
            try {
               
                // Or example of parallel requests using Promise.all
                const [phaseResponse, priorityResponse, tagsResponse, projCategoryResponse, designationResponse, workspaceList, userProfile] = await Promise.all([
                    axios.get(`${Constants.BASE_URL2}master/phases`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                    axios.get(`${Constants.BASE_URL2}master/priority`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                    axios.get(`${Constants.BASE_URL2}master/tags/list`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                    axios.get(`${Constants.BASE_URL2}master/project-category/list`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                    axios.get(`${Constants.BASE_URL2}master/designation/list`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                    axios.post(
                        `${Constants.BASE_URL2}workspaces`,
                        null,
                        {
                            headers: {
                                "x-api-key": "web_qwertyuiop",
                                "x-access-token": accessToken,
                                "Access-Control-Allow-Origin": "*",
                            },
                        }
                    ),
                    axios.get(`${Constants.BASE_URL2}profile`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),
                ]);
               
           if (phaseResponse?.status === 200) {

                    setPhases(phaseResponse?.data?.phases?.data)
                }
                if (priorityResponse?.status === 200) {

                    setPriority(priorityResponse?.data?.priority?.data)
                }
                if (tagsResponse?.status === 200) {
                    // Handle the response data if needed
                    setTags(tagsResponse?.data?.tags)
                }
                if (projCategoryResponse?.status === 200) {
                    // Handle the response data if needed
                    setProjCategory(projCategoryResponse?.data?.project_category)
                }
                if (designationResponse?.status === 200) {
                    // Handle the response data if needed
                    setDesignation(designationResponse?.data?.master_designation)
                }
                if (workspaceList?.status === 200) {
                    // setWorkspaceList(workspaceList?.data?.Workspaces?.data)
                    if (workSpace) {
                        const updatedWorkSpaceList = [workSpace, ...(workspaceList?.data?.Workspaces?.data).filter((workspace: any) => workspace.alias !== workSpace.alias)];
                        setWorkspaceList(updatedWorkSpaceList)
                    }

                }
                if(userProfile?.status === 200){
               
                    setIsUserAdmin(Number(userProfile?.data?.user?.default_workspace?.is_super_admin) === 1 ? true : false)
                    // setIsUserAdmin(workSpace?.is_super_admin ===  1 ? true : false)
                }

            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    if((error as AxiosError).response?.status === 461){
          
                        setTimeout(() => {
                            signOut();
                        }, 3000);
                    }
                    if((error as AxiosError).response?.status === 462){
                   
                        setTimeout(() => {
                            signOut();
                        }, 3000);
                    }
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    // toast.error('An unexpected error occurred', {
                    //     position: "top-center"
                    // });
                }
                if (error?.isAxiosError) {
                    if(error?.response?.status === 403){
                        signOut()
                    }
                }
            }
        };

        // Run the effect when the user logs in or the page is refreshed
        if (isAuthenticated && workSpaceTkn) {
            fetchUserFromLocalStorage();
        }
    }, [isAuthenticated, workSpaceTkn]);

    const refetchData = async () => {

        try {

            // Or example of parallel requests using Promise.all
            const [phaseResponse, priorityResponse, tagsResponse, projCategoryResponse, designationResponse, workspaceList] = await Promise.all([
                axios.get(`${Constants.BASE_URL2}master/phases`, {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn,
                        "Access-Control-Allow-Origin": "*"
                    },
                }),
                axios.get(`${Constants.BASE_URL2}master/priority`, {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn,
                        "Access-Control-Allow-Origin": "*"
                    },
                }),
                axios.get(`${Constants.BASE_URL2}master/tags/list`, {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn,
                        "Access-Control-Allow-Origin": "*"
                    },
                }),
                axios.get(`${Constants.BASE_URL2}master/project-category/list`, {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn,
                        "Access-Control-Allow-Origin": "*"
                    },
                }),
                axios.get(`${Constants.BASE_URL2}master/designation/list`, {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn,
                        "Access-Control-Allow-Origin": "*"
                    },
                }),
                axios.post(
                    `${Constants.BASE_URL2}workspaces`,
                    null,
                    {
                        headers: {
                            "x-api-key": "web_qwertyuiop",
                            "x-access-token": userTkn,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
            ]);

            if (phaseResponse?.status === 200) {
                setPhases(phaseResponse?.data?.phases?.data)
            }
            if (priorityResponse?.status === 200) {
                setPriority(priorityResponse?.data?.priority?.data)
            }
            if (tagsResponse?.status === 200) {
                // Handle the response data if needed
                setTags(tagsResponse?.data?.tags)
            }
            if (projCategoryResponse?.status === 200) {
                // Handle the response data if needed
                setProjCategory(projCategoryResponse?.data?.project_category)
            }
            if (designationResponse?.status === 200) {
                // Handle the response data if needed
                setDesignation(designationResponse?.data?.master_designation)
            }
            if (workspaceList?.status === 200) {
                setWorkspaceList(workspaceList?.data?.Workspaces?.data)
            }

        } catch (error) {
            console.error('Error fetching user from localStorage:', error);
        }
    };


    return <MasterContext.Provider value={{ phases, priority, tags, projCategory, designation, refetchData }}>
        {children}
    </MasterContext.Provider>;
}
