import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Constants from 'common/Constants';

interface ApiCallOptions {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: any;
    headers?: AxiosRequestConfig['headers'];
}

const apiCall = async ({ url, method, data, headers }: ApiCallOptions): Promise<any> => {
    try {
        const response: AxiosResponse = await axios({
            method,
            url: `${Constants.BASE_URL2}${url}`,
            data,
            headers: {
                'x-api-key': 'web_qwertyuiop', // Add custom headers here
                'Access-Control-Allow-Origin': '*',
                ...headers,
            },
        });

        return response; // Extract only the response data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // You can handle the error as per your application's requirements
    }
};

export default apiCall;

