import axios from 'axios';
import Button from 'components/base/Button';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Constants from 'common/Constants';
import logo from 'assets/img/icons/logo-sign.png';
import regexPatterns from 'common/regexPatterns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { EyeIcon2 } from 'components/common/EyeIcon';

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showAlert, setShowAlert] = useState<Boolean>(false)
  const [message, setMessage] = useState<string>('');
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('reset_token');
  const [errMessage, setErrorMessage] = useState<string>("")
  const [commonError, setCommonError] = useState<any>('')
  const [showPassword, setShowPassword] = useState(false);
  const [resetToken1, setResetToken1] = useState<string>("")
  const [error, setError] = useState({
    token: "",
    password: ""
  })


  useEffect(() => {
    // Verify Token
    // setResetToken1(resetToken);
    const verifyToken = async (resetToken: any) => { // Remove :String
      setResetToken1(resetToken);
      const formData = new FormData();
      formData.append('token', resetToken);
      try {
        const response = await axios.post(`${Constants.BASE_URL2}verify-token`, formData, {
          headers: {
            'x-api-key': 'web_qwertyuiop', // Add custom headers here
            "Access-Control-Allow-Origin": "*"
          },
        });
     

        if (response.status === 200) {
        
          // Dynamically set errors based on the response
          if (response?.data?.errors) {
            const errors = response?.data?.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setError((prevData) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            console.warn("first api response", response.data);

          }
        } else {
          console.error("api failed");
        }
      } catch (error: any) {
        console.error('Sign in failed:', error);
        const errorMessage = error.response?.data || 'An error occurred during sign-in.';
        setCommonError(errorMessage);
      }
    };
    verifyToken(resetToken);
  }, [resetToken]);



  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassowrd = async (event: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage("")
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else if (password !== confirmPassword) {
      setErrorMessage("Password and confirm password do not match");
    } else if (!regexPatterns.passwordRegex.test(password)) {
      // setErrorMessage("Password must start with a letter and be between 6 and 14 characters long");
      setError((prevData) => ({
        ...prevData,
        password: "Password must start with a letter and be between 6 and 14 characters long"
      }));
    } else {
      const formData = new FormData();
      formData.append('token', resetToken1);
      formData.append('password', password);
      formData.append('confirm_password', confirmPassword)

      try {
        const response = await axios.post(`${Constants.BASE_URL2}reset-password`, formData, {
          headers: {
            'x-api-key': 'web_qwertyuiop', // Add custom headers here
            "Access-Control-Allow-Origin": "*"
          },
        });
    

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data?.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setError((prevData) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
          
            setMessage(response.data.message)
            setShowAlert(true)
            setConfirmPassword('')
            setPassword('')
            setTimeout(() => {
              navigate("/sign-in")
            }, 1000);
            return true
          }

        } else {
          console.error("api failed");
        }
      } catch (error: any) {
        console.error('Sign in failed:', error);
        const errorMessage = error.response?.data || 'An error occurred during sign-in.';
        setCommonError(errorMessage);
      }
    }



  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="text-center mb-6">
      <div className="text-center mb-3">
        <img src={logo} alt="" width={160} className="rounded-2  mt-3" />
      </div>
      <h4 className="text-1000">Reset new password</h4>
      <p className="text-700">Type your new password</p>
      {showAlert && <Alert key={"success"} variant={"outline-" + "success"}>
        {message}
      </Alert>
      }
      {error?.token && <div style={{ marginBottom: "0.25rem", color: "#ed2000", fontSize: "75%", paddingLeft: "1rem" }}>
        {error?.token}
      </div>}
      <Form className="mt-5" noValidate validated={validated} onSubmit={handleResetPassowrd}>
        <Form.Group className="mb-3 text-start">
        <div className="form-icon-container">
          <Form.Control
            required
            minLength={6}
            className="form-icon-input"
            id="password"
            type="password"
            placeholder="Type new password"
            value={password}
            onChange={handlePasswordChange}
            isInvalid={error.password !== ""}
          />
             <FontAwesomeIcon
                icon={faKey}
                className="text-600 fs-9 form-icon"
              />
          <Form.Control.Feedback type="invalid" >
            {error?.password ? error?.password : "Password must be at least 6 characters long."}
          </Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
        <div className="form-icon-container">
          <Form.Control
            required
            minLength={6}
            id="confirmPassword"
            className="form-icon-input"
            type={showPassword ? "text" : "password"}
            placeholder="Cofirm new password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <EyeIcon2 togglePasswordVisibility={togglePasswordVisibility} showPassword={showPassword} />
             <FontAwesomeIcon
                icon={faKey}
                className="text-600 fs-9 form-icon"
              />
          <Form.Control.Feedback type="invalid">
            Password not matched
          </Form.Control.Feedback>
          </div>
        </Form.Group>
        {commonError?.errors?.token && <div style={{ marginBottom: "0.25rem", color: "#ed2000", fontSize: "75%", paddingLeft: "1rem", textAlign: "left" }}>
          {commonError?.error_code} {commonError?.errors?.token[0]}
        </div>}
        {
          errMessage &&
          <div style={{ marginBottom: "0.25rem", color: "#ed2000", fontSize: "75%", paddingLeft: "1rem", textAlign: "left" }}>
            {errMessage}
          </div>
        }
        <Button variant="outline-primary" className="w-100"
          // onClick={() => { handleResetPassowrd() }}
          type="submit"
        disabled={error.token ? true : loading === true ? true : false}
        >
          Set Password
        </Button>
        {error.token ? <Row className="flex-between-center mb-3 px-0">
          <Col xs="auto" className="px-0">
            
          </Col>
          <Col xs="auto" className="px-0 pe-3 pt-1">
            <Link to={`/forgot-password`} className="fs-9 fw-semi-bold">
              Forgot Password?
            </Link> or 
            <Link to={`/sign-in`} className="fs-9 fw-semi-bold">
              {" Sign In?"}
            </Link>
          </Col>
        </Row>
          : ""
        }
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
