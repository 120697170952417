import { faList, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Unicon from 'components/base/Unicon';
import { UilCube, UilSchedule, UilSync,  UilPen, UilCheck, UilThLarge, UilTrash,  UilUnlock, UilLock } from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Constants from "common/Constants";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState, useRef } from "react";
import { Link, LinkProps, NavigateFunction, useNavigate } from "react-router-dom";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { ChangeEvent } from "react";
import { Button, Card, Col, FloatingLabel, Form,  Row, Spinner, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import { useAuth } from "providers/AuthContext";
import { useMeta } from "providers/MetaContext";
import Avatar from "components/base/Avatar";
import ToggleViewButton from "components/common/ToggleViewbutton";
import { useMaster } from "providers/MasterContext";
import Badge from 'components/base/Badge';
import CustomPagination from "components/sp-common/CustomPagination";
import calculateSerialNumber from "common/calculateSerialNumber";
import DatePicker from 'components/base/DatePicker';


// Assuming you have a type for your data objects
type ProjectData = {
    title: string;
    category: string;
    phase: string;
    client_id: string;
    lastActive: string;
    editable: string;
    project_status: string;
    total_page: number;
    id: string;
    alias: string;
    status: number;
    short_name: string;
    serialNumber: number;
    // Add other properties as needed
    projectcategory?: {
        id: number;
        title: string;
        orderby: number;
        status: number;
        created_at: number;
        created_by: number;
        updated_at: number;
        updated_by: number;
    };
    projectphase?: {
        id: number;
        title: string;
        status: number;
        created_at: number;
        updated_at: number;
        created_by: number;
        updated_by: number;
    };
};

type SummaryData = {
    page: number;
    pageSize: number;
    total: number;
    total_page: number;
};

interface CustomLinkProps extends LinkProps {
    to: {
        pathname: string;
        state: {
            projectData: ProjectData;
        };
    };
}

const ProjectInfoSection = () => {
    const { userTkn } = useAuth();
    const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
    
    const { projectstatus } = useMeta();
    const { tags, projCategory,  phases } = useMaster();
    
    const [data, setData] = useState<ProjectData[] | null>(null);
    const [summary, setSummary] = useState<any | null>({
        "total": 0,
        "page": 1,
        "pageSize": 0,
        "total_page": 1,
    });
    const [indexValueFirst, setValueFirst] = useState<number | null>(1);
    const [indexValueLast, setValueLast] = useState<number | null>(1);
    const tableRef = useRef<HTMLTableElement | null>(null);

    //for filters
    const [pageIndex, setPageIndex] = useState<number | null>(1);
    const [tagsFilter, setTagsFilter] = useState<string | null>("");
    const [clientFilter, setClientFilter] = useState<string | null>("");
    const [categoryFilter, setCategoryFilter] = useState<string | null>("");
    const [phaseFilter, setPhaseFilter] = useState<string | null>("");
    const [statusFilter, setStatusFilter] = useState<string | null>("");
    const [editableFilter, setEditableFilter] = useState<string | null>("");
    const [titleSearch, setTitleSearch] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false); // Add loading state

    const [toggleView, setToggleView] = useState<boolean>(false);

    //for card view
    const containerRef = useRef<HTMLDivElement>(null);
    const [dataCard, setDataCard] = useState<any>([]);
    const [loadingCard, setLoadingCard] = useState(false);
    const [page, setPage] = useState(1);
    const [apiCalls, setApiCalls] = useState(0);
    const [totalPage, setTotalPage] = useState(0)

    const CustomLink: React.FC<CustomLinkProps> = ({ to, children, ...rest }) => (
        <Link to={to} {...rest}>
            {children}
        </Link>
    );
    const {
        config: { theme, navbarPosition },
        setConfig,
    } = useAppContext();

    //set top navigation
    useEffect(() => {
        const value = "combo";
        const value2 = "slim";
        
        setConfig({
            navbarPosition: value as NavPositionVariant,
            navbarTopShape: value2 as NavTopShapeVariant,
        });
    }, []);
    // called when filter changed

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                
                const response = await axios.post(
                    `${Constants.BASE_URL2}project/list?page=${pageIndex}&tag=${tagsFilter}&client=${clientFilter}&category=${categoryFilter}&phase=${phaseFilter}&status=${statusFilter}`,
                    null,
                    {
                        headers: {
                            "x-api-key": "web_qwertyuiop", // Add custom headers here
                            "x-access-token": userTkn,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                );
                

                if (response?.status === 200) {
                    // Calculate serial numbers for the projects on the current page
                    const updatedProjects = response?.data?.data?.projects.map((project: any, index: any) => ({
                        ...project,
                        serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
                    }));

                    
                    setData(updatedProjects);
                    let summary = response?.data?.data?.summary
                    setSummary({
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    });
                    setLoading(false);
                } else {
                    console.error("Api Failed");
                }
            } catch (error) {
                console.error("Sign in failed:", error);
            } finally {
                setLoading(false); // Set loading to false after the API call is completed
            }
        };
        fetchData();
    }, [userTkn, pageIndex, tagsFilter, clientFilter, categoryFilter, phaseFilter, statusFilter, editableFilter]);



    const handleSearchInputBlur = () => {
        // Fetch data based on the titleSearch value
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${Constants.BASE_URL2}project/list?page=${pageIndex}&tag=${tagsFilter}&client=${clientFilter}&category=${categoryFilter}&phases=${phaseFilter}&status=${statusFilter}&title=${titleSearch} `,
                    null,
                    {
                        headers: {
                            "x-api-key": "web_qwertyuiop", // Add custom headers here
                            "x-access-token": userTkn,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                );
                

                if (response?.status === 200) {
                    const updatedProjects = response?.data?.data?.projects.map((project: any, index: any) => ({
                        ...project,
                        serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
                    }));

                    
                    setData(updatedProjects);
                    let summary = response?.data?.data?.summary;
                    setSummary({    // Trigger the filter when the input field loses focus
                        // You can use the titleSearch state value to filter your data
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    });
                } else {
                    console.error("Api Failed");
                }
            } catch (error) {
                console.error("Sign in failed:", error);
            }
        };
        fetchData();
    };

    useSettingsMountEffect({
        showSettingPanelButton: false,
    });

    const table = useAdvanceTable({
        data: data || [],
        columns: projectsTablecolumns,
        pageSize: 10,
        pagination: true,
        sortable: true,
        // selection: true
    });

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        // table.setGlobalFilter(e.target.value || undefined);
        setTitleSearch(e.target.value);
    };
    // Inside your component




    useEffect(() => {
        // Now 'tableRef.current' contains the reference to the table element
        const table = tableRef.current;

        if (table) {
            const firstRow = table?.rows[1];
            const lastRowIndex = table?.rows.length - 1;
            const lastRow = table?.rows[lastRowIndex];
            
            if (firstRow) {
                const firstCell = firstRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    
                    let value = Number(cellValue)
                    setValueFirst(value)
                }
            }

            if (lastRow) {
                const firstCell = lastRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    
                    let value = Number(cellValue)
                    setValueLast(value)
                }
            }
        }
    }, [data]); // This effect runs once when the component mounts

    const getStatusIcon = (status: number) => {
        if (status === 0) return <Unicon icon={UilTrash} size={21} color="" fill="#6E7891" />
        else if (status === 1) return <Unicon icon={UilUnlock} size={21} color="" fill="#8FC644" />
        else if (status === 2) return <Unicon icon={UilLock} size={21} color="" fill="#6E7891" />
        else return <div></div>
    }


    const fetchData = async () => {
   
        try {
            setLoadingCard(true);
            const response = await axios.get(
                `${Constants.BASE_URL2}project/list?page=${page}`,
                {
                    headers: {
                        'x-api-key': 'web_qwertyuiop',
                        'x-access-token': userTkn,
                        'Access-Control-Allow-Origin': '*',
                    },
                }
            );

         
            setDataCard((prevData: any) => [...prevData, ...response?.data?.data?.projects]);
            setTotalPage(Number(response?.data?.data?.summary?.total_page))
            if (Number(apiCalls) < Number(response?.data?.data?.summary?.total_page)) {
                setApiCalls((prevCalls) => prevCalls + 1);
                setPage((prevPage) => prevPage + 1);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingCard(false);
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= (scrollHeight) && !loading) {
                // Check if total_page === page
                if (apiCalls >= totalPage) {
                    return; // Stop making API call
                }
                fetchData();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingCard, apiCalls, totalPage]);


    useEffect(() => {
        fetchData(); // Fetch initial data when the component mounts
    }, []); // Empty dependency array ensures the effect runs only o

    return (
        <div>

            <AdvanceTableProvider {...table}>
                <div className="d-flex flex-wrap mb-4 align-items-center justify-content-between">
                    <h2 className="mb-0">
                        <span className="me-3">  <Unicon icon={UilCube} size={28} /> Project Info</span>{" "}
                    </h2>

                </div>

                <div className="navbar_actions mb-3 mx-0">
                    <div className='d-flex gap-5 mobileSlide'>
                        <div className='d-flex align-items-center gap-4 '>
                            <Link to={``} className='fs-9 text-decoration-none fw-bold text-700' >WIKI</Link>
                            <Link to={``} className='fs-9 text-decoration-none fw-bold text-700'>Sections</Link>
                            <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Feature List</Link>
                            <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Link</Link>
                            <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Files</Link>
                        </div>


                    </div>
                </div>
                <Row className="g-3 justify-content-between align-items-center mb-3">
                    <Col xs={12} className="col-md-11 custom_selectWidth">
                        <div className="d-flex align-items-center gap-2 flex-wrap ">
                            {/* <Filtershort /> */}

                            <FloatingLabel controlId="lead-woner" label="Who">
                                <Form.Select
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                >
                                    <option value={""} key={"a1"}>{"Select"} </option>
                                    {projCategory &&
                                        projCategory
                                            .sort((a, b) => a.orderby - b.orderby) // Sort by orderby property
                                            .map((item, index: number) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item?.title}
                                                    </option>
                                                );
                                            })}
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInputGrid"
                                label={
                                    <>
                                        <FontAwesomeIcon icon={faSearch} />{" "}
                                        <span>What/ Tag</span>
                                    </>
                                }
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Project title"
                                    onChange={handleSearchInputChange}
                                    onBlur={handleSearchInputBlur}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInputGrid"
                                label=""
                                className="actionPoint"
                            >
                                <DatePicker
                                    options={{
                                        enableTime: true,
                                        dateFormat: 'd-m-y h:i',
                                    }}
                                    placeholder="WHEN DATE"

                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInputGrid"
                                label=""
                                className="actionPoint"

                            >
                                <DatePicker
                                    options={{
                                        enableTime: true,
                                        dateFormat: 'd-m-y h:i',
                                    }}
                                    placeholder="FINISH DATE"


                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="lead-woner" label="Status">
                                <Form.Select
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                >
                                    <option value={""} key={"a1"}>{"All"} </option>
                                    {projCategory &&
                                        projCategory
                                            .sort((a, b) => a.orderby - b.orderby) // Sort by orderby property
                                            .map((item, index: number) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {item?.title}
                                                    </option>
                                                );
                                            })}
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel controlId="lead-woner" label="Created By">
                                <Form.Select onChange={(e) => setPhaseFilter(e.target.value)}>
                                    <option value={""} key={"a1"}>
                                        {"All"}
                                    </option>
                                    {phases &&
                                        phases?.map((item, index: number) => {
                                            return (
                                                <option value={item.id} key={item.id}>
                                                    {item?.title}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel controlId="lead-woner" label="Auditor">
                                <Form.Select onChange={(e) => setPhaseFilter(e.target.value)}>
                                    <option value={""} key={"a1"}>
                                        {"All"}
                                    </option>
                                    {phases &&
                                        phases?.map((item, index: number) => {
                                            return (
                                                <option value={item.id} key={item.id}>
                                                    {item?.title}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel controlId="lead-woner" label="WBS">
                                <Form.Select
                                    onChange={(e) => {
                                        setEditableFilter(e.target.value);
                                    }}
                                >
                                    {/* <option value="">All</option> */}
                                    <option value="1">Enable</option>
                                    <option value="0">Disable</option>
                                </Form.Select>
                            </FloatingLabel>


                        </div>
                    </Col>
                    <Col xs={12} className="col-md-1">
                        <div className="d-flex align-items-center gap-2 justify-content-end">
                            <div className="d-md-block d-none">
                                <ToggleViewButton
                                    tooltip="List view"
                                    active={toggleView === false}
                                    onClick={() => {
                                        setToggleView(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faList} className="fs-9" />
                                </ToggleViewButton>
                            </div>
                            <div className="d-md-block d-none">
                                <ToggleViewButton
                                    tooltip="Card view"
                                    active={toggleView === true}
                                    onClick={() => {
                                        setToggleView(true);
                                    }}
                                >
                                    <Unicon icon={UilThLarge} size={14} />
                                </ToggleViewButton>
                            </div>
                        </div>
                    </Col>

                </Row>
                <div className="d-md-block d-none">
                    {!toggleView ? (

                        <PhoenixDocCard className="p-sm-4 p-2 mt-3 ">
                            <div className="d-flex flex-wrap mb-3 align-items-center justify-content-end">
                                <Link
                                    className="btn btn-outline-primary px-5"
                                    to=""
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    New Section
                                </Link>
                            </div>
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="light" />{" "}
                                </div>
                            ) :
                                (
                                    <>
                                        <Table
                                            className="phoenix-table fs-9 phoenix-table fs-9 project-table project_padding border rounded"
                                            size="sm"
                                            striped
                                            hover
                                            responsive
                                            ref={tableRef}
                                        >
                                            <thead>
                                                <tr id={"a1"} >
                                                    <th style={{ width: "2%", minWidth: "10px", padding: "16px 0px" }}>#</th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "15%", minWidth: "10px" }}
                                                    >
                                                        TITLE
                                                    </th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "28%", minWidth: "80px" }}
                                                    >
                                                        DESCRIPTION
                                                    </th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "7.5%", minWidth: "100px" }}
                                                    >
                                                        DATE

                                                    </th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "6%", minWidth: "100px" }}
                                                    >
                                                        ORDER BY
                                                    </th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "6%", minWidth: "100px" }}
                                                    >
                                                        IMAGE
                                                    </th>
                                                    <th
                                                        className="px-1"
                                                        style={{ width: "7.5%", minWidth: "100px" }}
                                                    >
                                                        Action
                                                    </th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td> <a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy</a></td>
                                                    <td><a className="text-decoration-none fw-semi-bold fs-8 mb-0  text-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a></td>
                                                    <td>12/12/2023</td>
                                                    <td>02</td>
                                                    <td></td>
                                                    <td> <Button
                                                        variant="phoenix-primary"
                                                        className="btn-icon rounded-1 bg-transparent"

                                                    >
                                                        <Unicon icon={UilPen} size={18} />
                                                    </Button></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                                            <Col className="d-flex fs-9">
                                                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                                                    {indexValueFirst} to {indexValueLast}
                                                    <span className="text-600"> items of </span>
                                                    {summary?.total}
                                                </p>
                                            </Col>
                                            <Col xs="auto">
                                                <CustomPagination
                                                    pageIndex={pageIndex}
                                                    totalPage={summary?.total_page}
                                                    activePage={Number(summary?.page)}
                                                    setPageIndex={setPageIndex}
                                                />
                                            </Col>
                                        </Row>
                                            :
                                            <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                                                <span className="text-600"> No record found !!{summary?.total}</span>
                                            </p>
                                        }
                                    </>
                                )
                            }
                        </PhoenixDocCard>
                    ) : (
                        <div ref={containerRef}>
                            <Row className="g-3 mb-4 ">
                                {dataCard && Array.isArray(dataCard) && dataCard.map((project: ProjectData, index: number) => (
                                    <Col xs={12} md={6} xl={4} xxl={3} key={index}>
                                        <Card className="h-100 hover-actions-trigger">
                                            <Card.Body>
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <div>
                                                        <p className="mb-0 text-success fs-8 fw-semi-bold">Completed</p>
                                                    </div>
                                                    <div className="d-flex gap-2 justify-content-end ">
                                                        <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                            < Unicon icon={UilSync} size={20} id="not-clickable" />
                                                            <Tooltip anchorSelect="#not-clickable">Reopen</Tooltip>
                                                        </Button>

                                                        <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                            <Unicon icon={UilPen} size={20} id="Meeting" />
                                                            <Tooltip anchorSelect="#Meeting" clickable>Edit</Tooltip>
                                                        </Button>
                                                        <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                            <Unicon icon={UilCheck} size={20} id="notes" />
                                                            <Tooltip anchorSelect="#notes" clickable>Complete</Tooltip>
                                                        </Button>

                                                        <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                            <Unicon icon={UilSchedule} size={20} id="ActionPoint" />
                                                            <Tooltip anchorSelect="#ActionPoint" clickable>Expect Date & Time</Tooltip>
                                                        </Button>


                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar_width w-auto align-items-center">
                                                        <Avatar size="s" variant="name" className="me-1">
                                                            {project?.title?.slice(0, 2)}
                                                        </Avatar>
                                                    </div>
                                                    <Link
                                                        state={{ user: project }}
                                                        to={{
                                                            pathname: `/project/${project.alias}/overview`,
                                                        }}
                                                        className="text-decoration-none fw-bold fs-8 "
                                                    >
                                                        {project?.title}
                                                    </Link>
                                                </div>
                                                <div className="d-flex justify-content-between py-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar_width w-auto align-items-center">
                                                            <Avatar size="s" variant="name" className="me-1">
                                                                {project?.title?.slice(0, 2)}
                                                            </Avatar>
                                                        </div>
                                                        <p className="mb-0 fs-9">07/12/2023, 12:10</p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <p className="mb-0 fs-9">07/12/2023, 12:10</p>
                                                        <div className="avatar_width w-auto align-items-center">
                                                            <Avatar size="s" variant="name" className="me-1">
                                                                {project?.title?.slice(0, 2)}
                                                            </Avatar>
                                                        </div>

                                                    </div>
                                                </div>
                                                <Row className="d-flex  text-700 fw-semi-bold ">
                                                    <Col className="d-flex align-items-center">
                                                        <div className="fs-9 mt-2 d-flex align-items-center gap-1">
                                                            {"Tags: "}
                                                            <div className="d-flex gap-1">
                                                                <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui Design</Badge>
                                                                <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui/Ux</Badge>
                                                                <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui Design</Badge>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            {loading && <Spinner animation="border" variant="primary" />}
                        </div>
                    )}
                </div>
                <Row className="g-3 py-1 d-md-none d-block">
                    {dataCard && Array.isArray(dataCard) && dataCard.map((project: ProjectData, index: number) => (
                        <Col xs={12} md={6} xl={4} xxl={3} key={index}>
                            <Card className="h-100 hover-actions-trigger">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div>
                                            <p className="mb-0 text-success fs-8 fw-semi-bold">Completed</p>
                                        </div>
                                        <div className="d-flex gap-2 justify-content-end ">
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                < Unicon icon={UilSync} size={20} id="not-clickable" />
                                                <Tooltip anchorSelect="#not-clickable">Reopen</Tooltip>
                                            </Button>

                                            <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                <Unicon icon={UilPen} size={20} id="Meeting" />
                                                <Tooltip anchorSelect="#Meeting" clickable>Edit</Tooltip>
                                            </Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                <Unicon icon={UilCheck} size={20} id="notes" />
                                                <Tooltip anchorSelect="#notes" clickable>Complete</Tooltip>
                                            </Button>

                                            <Button variant='phoenix-primary' className="p-1 bg-transparent">
                                                <Unicon icon={UilSchedule} size={20} id="ActionPoint" />
                                                <Tooltip anchorSelect="#ActionPoint" clickable>Expect Date & Time</Tooltip>
                                            </Button>


                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar_width w-auto align-items-center">
                                            <Avatar size="s" variant="name" className="me-1">
                                                {project?.title?.slice(0, 2)}
                                            </Avatar>
                                        </div>
                                        <Link
                                            state={{ user: project }}
                                            to={{
                                                pathname: `/project/${project.alias}/overview`,
                                            }}
                                            className="text-decoration-none fw-bold fs-8 "
                                        >
                                            {project?.title}
                                        </Link>
                                    </div>
                                    <div className="d-flex justify-content-between py-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar_width w-auto align-items-center">
                                                <Avatar size="s" variant="name" className="me-1">
                                                    {project?.title?.slice(0, 2)}
                                                </Avatar>
                                            </div>
                                            <p className="mb-0 fs-9">07/12/2023, 12:10</p>
                                        </div>
                                        <div className="d-flex align-items-center gap-1">
                                            <p className="mb-0 fs-9">07/12/2023, 12:10</p>
                                            <div className="avatar_width w-auto align-items-center">
                                                <Avatar size="s" variant="name" className="me-1">
                                                    {project?.title?.slice(0, 2)}
                                                </Avatar>
                                            </div>

                                        </div>
                                    </div>
                                    <Row className="d-flex  text-700 fw-semi-bold ">
                                        <Col className="d-flex align-items-center">
                                            <div className="fs-9 mt-2 d-flex align-items-center gap-1">
                                                {"Tags: "}
                                                <div className="d-flex gap-1">
                                                    <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui Design</Badge>
                                                    <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui/Ux</Badge>
                                                    <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui Design</Badge>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col></Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>


            </AdvanceTableProvider>

        </div>
    );
};

export default ProjectInfoSection;