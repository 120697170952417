// AuthContext.tsx
import axios from "axios";
import Constants from "common/Constants";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { } from 'react-device-detect';
import {browserName, browserVersion , osName, osVersion , deviceType } from 'react-device-detect';

// Define the user type based on the structure of the user object
type UserType = {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  is_external: number;
  client_id: number;
  userTkn: string;
  error: string;
  workspaces: WorkSpace[];
};

type WorkSpace = {
  id: number;
  uuid: string;
  title: string;
  alias: string;
  status: number;
  is_super_admin: number;
  is_default: boolean;
}

// Define the context type
type AuthContextType = {
  isAuthenticated: boolean;
  signIn: (
    email: string,
    password: string,
    setCommonError: (error: string) => void,
    handleError: any
  ) => void; // Accept email and password as parameters
  signInGoogle: (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void
  ) => void; // Accept email and password as parameters
  signInMicroSoft: (
    source: string,
    source_id: string,
    email: string,
    setMicroSoftAuthError: (error: string) => void
  ) => void; // Accept email and password as parameters
  signOut: () => void;
  user: UserType | null; // User property with the defined user type,
  userTkn: string | null; // Add the userTkn property
  isUserAdmin: boolean;
  workSpace: WorkSpace | null;
  workSpaceTkn: string | null;
  workSpaceList: WorkSpace[];
  setWorkspace: (workspace: WorkSpace | null) => void;
  setWorkspaceList: Dispatch<SetStateAction<WorkSpace[]>>;
  setWorkSpaceTkn: (token: string | null) => void;
  setIsUserAdmin: (isAdmin: boolean) => void;
  setIsAuthenticated: (isAuthenticated:boolean)=>void;
  setUser: ( user: UserType | null)=>void
  setUserTkn:(token: string | null) => void;
};

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define a helper function to use the context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

// Define the AuthProvider component
type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [workSpace, setWorkspace] = useState<WorkSpace | null>(null);
  const [workSpaceList, setWorkspaceList] = useState<WorkSpace[]>([]);
  const [userTkn, setUserTkn] = useState<string | null>(null); // Change the type to string | null
  const [workSpaceTkn, setWorkSpaceTkn] = useState<string | null>(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);



  const signIn = async (
    email: string,
    password: string,
    setCommonError: (error: string) => void,
    handleError: any
  ) => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)
    try {
      const response = await axios.post(
        `${Constants.BASE_URL2}login`,
        formData,
        {
          headers: {
            "x-api-key": "web_qwertyuiop", // Add custom headers here
            "Access-Control-Allow-Origin": "*",
          },
        }
      );


      if (response.status === 200) {

        if (response?.data?.errors) {
          setCommonError(response?.data);
          handleError(response?.data)
        } else {


          setUserTkn(response.data.access_token);
          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.access_token)
          );
          try {
            // const secondApiResponse = await axios.post(
            //   `${Constants.BASE_URL2}profile`,
            //   null,
            //   {
            //     headers: {
            //       "x-api-key": "web_qwertyuiop",
            //       "x-access-token": response.data.access_token,
            //       "Access-Control-Allow-Origin": "*",
            //     },
            //   }
            // );

            // Profile and Workspaces API calls together
            const [profileResponse, workspaceResponse] = await Promise.all([
              axios.post(
                `${Constants.BASE_URL2}profile`,
                null,
                {
                  headers: {
                    "x-api-key": "web_qwertyuiop",
                    "x-access-token": response.data.access_token,
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              ),
              axios.post(
                `${Constants.BASE_URL2}workspaces`,
                null,
                {
                  headers: {
                    "x-api-key": "web_qwertyuiop",
                    "x-access-token": response.data.access_token,
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              ),
            ]);


            if (profileResponse.status === 200 && workspaceResponse.status === 200) {

              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
              } else {
                // Optionally, update the user state with additional information from the second API response
                localStorage.setItem(
                  "user",
                  JSON.stringify(profileResponse?.data)
                );
                localStorage.setItem(
                  "workspacesList",
                  JSON.stringify(workspaceResponse?.data?.Workspaces?.data)
                );
                setUser(profileResponse?.data?.user);

                setIsAuthenticated(true);
               

                const defaultWorkspace = profileResponse?.data?.user?.default_workspace;
        
                 if (defaultWorkspace) {
                  setIsUserAdmin(defaultWorkspace?.is_super_admin);
                  setIsAuthenticated(true);
                  setWorkspace(defaultWorkspace);
                  setWorkSpaceTkn(defaultWorkspace.uuid);
                  const updatedWorkSpaceList = [defaultWorkspace, ...(workspaceResponse?.data?.Workspaces?.data).filter((workspace:any) => workspace.alias !== defaultWorkspace.alias)];
              
                  setWorkspaceList(updatedWorkSpaceList)
                  localStorage.setItem("isUserAdmin", JSON.stringify(defaultWorkspace.is_super_admin));
                  localStorage.setItem("workspaceToken", JSON.stringify(defaultWorkspace.uuid));
                  localStorage.setItem("selectedWorkSpace", JSON.stringify(defaultWorkspace));
                } else {
                  console.error("No workspaces available.");
                }
              }
            } else {
              console.error("Api Failed");
            }
          } catch (error) {
            console.error("Error:", error);
          }
          return true;
        }

      } else {
        setIsAuthenticated(false);

      }
    } catch (error: any) {
      setIsAuthenticated(false);
      console.error("Sign in failed:", error);
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setCommonError(errorMessage);
    }
  };
  const signInGoogle = async (
    source: string,
    source_id: string,
    email: string,
    setGoogleAuthError: (error: string) => void
  ) => {
    const formData = new FormData();
    formData.append("source", source);
    formData.append("source_id", source_id);
    formData.append("email", email);
    formData.append("platform", osName);
    formData.append("platform_version", osVersion);
    formData.append("browser", browserName);
    formData.append("browser_version", browserVersion);
    formData.append("device", deviceType)

    try {
      const response = await axios.post(
        `${Constants.BASE_URL2}social-login`,
        formData,
        {
          headers: {
            "x-api-key": "web_qwertyuiop", // Add custom headers here
            "Access-Control-Allow-Origin": "*",
          },
        }
      );


      if (response.status === 200) {

        if (response?.data?.errors) {
          setGoogleAuthError(response?.data);
        } else {


          setUserTkn(response.data.access_token);

          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.access_token)
          );
          try {
            const [profileResponse, workspaceResponse] = await Promise.all([
              axios.post(
                `${Constants.BASE_URL2}profile`,
                null,
                {
                  headers: {
                    "x-api-key": "web_qwertyuiop",
                    "x-access-token": response.data.access_token,
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              ),
              axios.post(
                `${Constants.BASE_URL2}workspaces`,
                null,
                {
                  headers: {
                    "x-api-key": "web_qwertyuiop",
                    "x-access-token": response.data.access_token,
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              ),
            ]);

            if (profileResponse.status === 200 && workspaceResponse.status === 200) {

              if (profileResponse?.data?.errors) {
                console.warn("error", profileResponse?.data);
              } else {
                // Optionally, update the user state with additional information from the second API response
                localStorage.setItem(
                  "user",
                  JSON.stringify(profileResponse.data)
                );
                localStorage.setItem(
                  "workspacesList",
                  JSON.stringify(workspaceResponse.data.Workspaces.data)
                );
                setUser(profileResponse?.data?.user);


                // setWorkspaceList(workspaceResponse?.data?.Workspaces?.data)

                const defaultWorkspace = profileResponse?.data?.user?.default_workspace;
            
                if (defaultWorkspace) {
                  setIsUserAdmin(defaultWorkspace?.is_super_admin);
                  setIsAuthenticated(true);
                  setWorkspace(defaultWorkspace);
                  const updatedWorkSpaceList = [defaultWorkspace, ...(workspaceResponse?.data?.Workspaces?.data).filter((workspace:any) => workspace.alias !== defaultWorkspace.alias)];
                 
                  setWorkspaceList(updatedWorkSpaceList)
                  setWorkSpaceTkn(defaultWorkspace.uuid);
                  localStorage.setItem("isUserAdmin", JSON.stringify(defaultWorkspace.is_super_admin));
                  localStorage.setItem("workspaceToken", JSON.stringify(defaultWorkspace.uuid));
                  localStorage.setItem("selectedWorkSpace", JSON.stringify(defaultWorkspace));
                } else {
                  console.error("No workspaces available.");
                }
              }
            } else {
              console.error("Api Failed");
            }
          } catch (error) {
            console.error("Sign in failed:", error);
          }
        }

      } else {

        setIsAuthenticated(false);
        setGoogleAuthError("Sign-in failed. Please try again.");
      }
    } catch (error: any) {
      console.error("Sign in failed:", error);

      setIsAuthenticated(false);
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setGoogleAuthError(errorMessage);
    }
  };

  const signInMicroSoft = async (
    source: string,
    source_id: string,
    email: string,
    setMicroSoftAuthError: (error: string) => void
  ) => {

    const formData = new FormData();
    formData.append("source", source);
    formData.append("source_id", source_id);
    formData.append("email", email);



    try {
      const response = await axios.post(
        `${Constants.BASE_URL2}social-login`,
        formData,
        {
          headers: {
            "x-api-key": "web_qwertyuiop", // Add custom headers here
            "Access-Control-Allow-Origin": "*",
          },
        }
      );


      if (response.status === 200) {

        if (response?.data?.errors) {
          setMicroSoftAuthError(response?.data);
        } else {
          setIsAuthenticated(true);

          setUserTkn(response.data.access_token);

          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.access_token)
          );
          try {
            const secondApiResponse = await axios.post(
              `${Constants.BASE_URL2}profile`,
              null,
              {
                headers: {
                  "x-api-key": "web_qwertyuiop",
                  "x-access-token": response.data.access_token,
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );

            if (secondApiResponse.status === 200) {


              // Optionally, update the user state with additional information from the second API response
              if (response?.data?.errors) {
                console.warn("error", response?.data);
              } else {
                localStorage.setItem(
                  "user",
                  JSON.stringify(secondApiResponse.data)
                );
                localStorage.setItem(
                  "isUserAdmin",
                  JSON.stringify(secondApiResponse.data.user.is_super_admin)
                );

                setUser(secondApiResponse.data.user);
                setIsUserAdmin(secondApiResponse.data.user.is_super_admin);
              }

            } else {
              console.error("Api Failed");
            }
          } catch (error) {
            console.error("Sign in failed:", error);
          }
        }

      } else {
        setIsAuthenticated(false);
        setMicroSoftAuthError("Sign-in failed. Please try again.");
      }
    } catch (error: any) {
      console.error("Sign in failed:", error);

      setIsAuthenticated(false);
      const errorMessage =
        error.response?.data || "An error occurred during sign-in.";
      setMicroSoftAuthError(errorMessage);
    }
  };
  const signOut = async () => {
    // Implement your sign-out logic here
    // setIsAuthenticated(false);
    // localStorage.removeItem("user")
    // localStorage.removeItem("accessToken")
    let accessToken: any = JSON.parse(
      localStorage.getItem("accessToken") || "{}"
    );


    try {
      const response = await axios.post(`${Constants.BASE_URL2}logout`, null, {
        headers: {
          "x-api-key": "web_qwertyuiop", // Add custom headers here
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
        },
      });


      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("isUserAdmin");
        localStorage.removeItem("selectedWorkSpace");
        localStorage.removeItem("workspaceToken");
        localStorage.removeItem("workspacesList");
        localStorage.removeItem("user");

        setUserTkn(null);
        setIsUserAdmin(false)
        setWorkspace(null)
        setWorkSpaceTkn(null)
        setWorkspaceList([])
        setUser(null);
        setIsAuthenticated(false);
      } else {
        console.error("Api Failed");
      }
    } catch (error: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("isUserAdmin");
      localStorage.removeItem("selectedWorkSpace");
      localStorage.removeItem("workspaceToken");
      localStorage.removeItem("workspacesList");
      localStorage.removeItem("user");

      setUserTkn(null);
      setIsUserAdmin(false)
      setWorkspace(null)
      setWorkSpaceTkn(null)
      setWorkspaceList([])
      setUser(null);
      setIsAuthenticated(false);
      console.error("sign out failed:", error.message);
      console.error("sign out failed:", error.response?.data.message);
      if (error.response?.data.message === "Invalid Access token") {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        setIsAuthenticated(false);
        setUser(null);
        setUserTkn(null);
      }
    }
  };

  useEffect(() => {
    const fetchUserFromLocalStorage = async () => {
    
      try {
        let temp: any = JSON.parse(localStorage.getItem("user") || "{}");
        let accessToken: any = JSON.parse(
          localStorage.getItem("accessToken") || "{}"
        );
        let isUserAdmin: any = JSON.parse(
          localStorage.getItem("isUserAdmin") || "{}"
        );
        let selectedWorkspace: any = JSON.parse(
          localStorage.getItem("selectedWorkSpace") || "{}"
        );
        let workSpaceToken: any = JSON.parse(
          localStorage.getItem("workspaceToken") || "{}"
        );
        let workSpaceList: any = JSON.parse(
          localStorage.getItem("workspacesList") || "{}"
        );


        if (
          temp &&
          temp.user &&
          accessToken &&
          accessToken.length > 0 &&
          selectedWorkspace !== null &&
          Object.keys(selectedWorkspace).length > 0 &&
          workSpaceToken !== null &&
          Object.keys(workSpaceToken).length > 0
        ) {

          setIsAuthenticated(true);
          setUser(temp?.user);
          setUserTkn(accessToken);
          setIsUserAdmin(isUserAdmin);
          setWorkSpaceTkn(workSpaceToken);
          setWorkspace(selectedWorkspace);
          const updatedWorkSpaceList = [selectedWorkspace, ...workSpaceList?.filter((workspace:any) => workspace.alias !== selectedWorkspace.alias)];
          setWorkspaceList(updatedWorkSpaceList)
        } else {
          // If selectedWorkspace is null or empty, navigate to set-workspace
          <Navigate to="/set-workspace" />;
        }
      } catch (error) {
        console.error("Error fetching user from localStorage:", error);
      }
    };

    fetchUserFromLocalStorage();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,

        user,
        userTkn,
        isUserAdmin,
        workSpace,
        workSpaceTkn,
        workSpaceList,
        setIsAuthenticated,
        setUser,
        setUserTkn,
        setWorkspace,
        setWorkspaceList,
        setWorkSpaceTkn,
        setIsUserAdmin,
        signIn,
        signOut,
        signInGoogle,
        signInMicroSoft
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
